import * as React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { GoogleMap } from "../components/GoogleMap"

const flex = css`
  flex: 1;
`
const whiteBackground = css`
  background-color: #fff;
`

const greyBackground = css`
  background-color: #edf1f1;
`

const pinkBackground = css`
  background-color: #ffeffe;
`

const purpleColor = css`
  color: #5d1d59;
`

const Logo: React.FC = () => {
  return <StaticImage css={flex} src="../images/GoInsideLogo.png" alt="Go Inside Barre" height={1000} width={1000} />
}

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: ${props => props.center ? 'center' : 'start'}
`


const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: ${props => props.center ? 'center' : 'start'};
  padding: ${props => props.noPadding ? '0' : '10px'};
`

const WideColumn = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: ${props => props.center ? 'center' : 'start'};
  padding: ${props => props.noPadding ? '0' : '10px'};
`

const FullBanner = styled.section`
  display: flex;
  justify-content: center;
  min-height: 100vh;
`

const TwoColumnSection = styled.section`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`

const ColumnLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
`

const WhatIsIt: React.FC = () => {
  return (<ColumnLayout css={whiteBackground}>
    <h1>¿Qué es?</h1>
    <h4>
    El Barre es un método de entrenamiento que fusiona las técnicas de barra de ballet con pilates y fitness.
    </h4>
    <h6>
    En Go Inside Barre además de trabajar todos los grupos musculares de una forma dinámica y divertida conectamos con nuestro interior, trabajando el cuerpo como unidad, desde adentro hacia afuera, entendiendo la materia y nuestra salud como un reflejo de nuestro interior, de nuestros pensamientos y emociones. 
    </h6>
  </ColumnLayout>)
}
// markup
const IndexPage = () => {
  return (
    <Layout>
      <FullBanner>
        <Logo />
      </FullBanner>
      <TwoColumnSection>
        <Logo />
        <WhatIsIt />
      </TwoColumnSection>
      <FullBanner>
        <ColumnLayout css={css`${whiteBackground};align-items: center`}>
          <h3>Movimiento que sana.</h3>
          <h2 css={css`text-align: center;`}>Tanto el Método Barre como el Método Pilates surgen a partir de una enfermedad o lesión y combinan ejercicios realizados en terapias de rehabilitación.</h2>
          <h5 css={css`text-align: center;`}>Lotte Berk - Método Barre<br />
Joseph Hubertus Pilates - Método Pilates</h5>
        </ColumnLayout>
      </FullBanner>
      <FullBanner>
        <Row center>
          <Column center>
            <StaticImage src="../images/etapas.jpeg" alt="Las Etapas" height={300} width={450} />
            <h4>Etapas de la Clase</h4>
            <p>Cada clase se compone por 6 etapas:
Warming up: Entrada en calor.
Brazos: Focalizamos en brazos y hombros.
Barra: Lado izquierdo y lado derecho.
Mat: Predomina la fuerza abdominal.
Estiramiento: Vuelta a la calma.
Meditacion: Trabajamos nuestras emociones.
Es una disciplina que une el dinamismo y la fuerza muscular con el control mental y el equilibrio. Mejoramos la actividad cardiovascular y conectamos con nuestro corazón y nuestros sentimientos. 
En una sola clase de Barre pasas por todo! intervalos aeróbicos, trabajos isométricos, podemos trabajar tambien el suelo pelvio y hasta la glandula Timo.</p>
          </Column>
          <Column center>
            <StaticImage src="../images/elementos.jpeg" alt="Los Elementos" height={300} width={450} />
            <h4>Los Elementos</h4>
            <p>El elemento principal es la Barra, que se utiliza siempre en la etapa de "Barra" y en algunas ocasiones en "Mat" En segundo lugar esta precisamente el Mat el cual usamos siempre en las ultimas 3 fases de la clase.
En la parte de "Brazos" mayormente usamos Mancuernas o Pesitas pero este elemento puede variar segun la clase y necesidades del alumno/a.
También trabajamos con Bandas, Elasticos, Pelota y Slides que podemos sumar en diferentes etapas.  
Todos estos materiales son facilitados por Go Inside Barre.
La diversidad de elementos y ejercicios hacen que cada clase sea única y diferente.
Los elementos pueden ser reemplazados por cosas de uso cotidiano para las clases por zoom.</p>
          </Column>
          <Column center>
            <StaticImage src="../images/musica.jpeg" alt="La Musica" height={300} width={450} />
            <h4>La Música</h4>
            <p>Nuestra playlist es tan diversa y ecléctica como las personas que asisten a nuestras clases.
Pero nuestro hilo conductor es la música con vibración alta. Tanto en las etapas dinámicas y de cardio como en las de concentración plena o relajación. 
En nuestra comunidad somos muy conscientes de todo lo que ingresa a nuestro cuerpo y los sonidos y palabras no quedan exentos. Las letras de canciones que seleccionamos hablan de temas como nuestra fuerza interior, la naturaleza o temas positivos.
Para la meditación también usamos frecuencias de 432hz que ayudan a la reconexión con nuestro interior y a sanar emociones estancadas.</p>
          </Column>
        </Row>
      </FullBanner>
      <FullBanner css={greyBackground}>
        <Column noPadding>
          <StaticImage src="../images/movimiento.jpeg" alt="En Movimiento" transformOptions={{fit: "cover"}} css={css`margin-left: -9vh;`}/>
        </Column>
        <WideColumn center>
          <Row center>
            <Column center> 
              <h2 css={purpleColor}>El cuerpo humano no fue diseñado para estar quieto.</h2>
              <h4 css={purpleColor}>El cuerpo debe ser motivado y sobre todo tener un sentido. </h4>
              <h5 css={purpleColor}>¿por que me muevo? ¿Para que?</h5>
            </Column>
          </Row>
        </WideColumn>
      </FullBanner>
      <FullBanner css={greyBackground}>
        <Row center>
          <Column center css={css`padding: 10vw;`}> 
            <h1 css={purpleColor}>Propósito</h1>
            <h3 css={purpleColor}>Cada persona tiene un propósito.</h3>
            <h5>Go Inside Barre es un entrenamiento que te invita a fortalecerte para alinearte con ese propósito que esta en tu interior.</h5>
          </Column>
          <Column center>
            <StaticImage src="../images/proposito.jpeg" alt="Tu Propósito" height={800}/>
          </Column>
        </Row>
      </FullBanner>
      <FullBanner css={greyBackground}>
        <Row center>
          <Column css={css`padding: 10vw;z-index: 10;${greyBackground};`}> 
            <h1 css={purpleColor}>Comunidad</h1>
            <h2 css={css`${purpleColor};margin-top: -5vh;`}>En movimiento</h2>
            <h5>Queremos ser una comunidad de personas en movimiento consciente y constante individualmente para lograr así una sinergia corporal masiva.</h5>
          </Column>
          <WideColumn center>
            <StaticImage src="../images/comunidad.jpeg" alt="Tu Comunidad" transformOptions={{fit: "cover"}} css={css`margin-left: -35vh;opacity: 70%;`}/>
          </WideColumn>
        </Row>
      </FullBanner>
      <FullBanner css={greyBackground}>
        <Row center>
        <WideColumn center noPadding>
            <StaticImage src="../images/quienes.jpeg" alt="Quienes" transformOptions={{fit: "cover"}} css={css`margin-right: -35vh;opacity: 70%;`}/>
          </WideColumn>
          <Column css={css`padding: 10vw;z-index: 10;${greyBackground};`}> 
            <h2 css={purpleColor}>¿Quienes pueden formar parte?</h2>
            <h4 css={css`${purpleColor};margin-top: -3vh;`}>Cualquier persona mayor de 14 años que este dispuesta a conectar con su cuerpo y sus emociones. </h4>
            <h5>Los unicos limites estan en nuestros pensamientos.</h5>
            <h6>Cualquiera sea tu condición corporal, sobrepeso, obesidad, tercera edad, lesiones, embarazadas o post-parto. Todos los ejercicios se adaptan no solo para que puedas hacer la actividad sino tambien para que el movimiento te ayude a sentirte cada vez mejor.
Donde hay un bloqueo físico hay un bloqueo emocional y que mejor forma para desbloquearlo que saber interpretar los mensajes de nuestro cuerpo trabajando con él de forma conjunta, integrando y escuchando.</h6>
          </Column>
        </Row>
      </FullBanner>
      <FullBanner css={pinkBackground}>
        <Row center>
          <Column center>
            <StaticImage src="../images/ubicacion.png" alt="Quienes" transformOptions={{fit: "cover"}}/>
          </Column>
          <Column css={css`padding: 60px;`}>
            <h1 css={purpleColor}>¿Donde?</h1>
            <h3 css={purpleColor}>Colinas del Cerro, Córdoba Capital</h3>
            <h4>En uno de los mejores barrios de la zona norte de Córdoba, en un espacio rodeado de verde y buenas energias.</h4>
          </Column>
        </Row>
      </FullBanner>
      <FullBanner>
        <Row center css={css`padding-left: 15vw;padding-right:15vw;`}>
        <Column center>
          <h1 css={css`${purpleColor};text-align: center;`}>Si queres resultados diferentes hace cosas diferentes</h1>
          <h2>¿Te animas?</h2>
          <h5>Instructora: Leslie Wendler</h5>
        </Column>
        </Row>
      </FullBanner>
      <FullBanner css={whiteBackground}>
        <Row center css={css`padding-left: 15vw;padding-right:15vw;`}>
          <Column center>
            <h1>Encontranos en:</h1>
            <Row center>
              <h4 css={css`margin-right: 3vw;`}>Facebook:</h4>
              <h6>@goinsidebarre</h6>
            </Row>
            <Row center>
              <h4 css={css`margin-right: 3vw;`}>Instagram:</h4>
              <h6>@go_inside_barre</h6>
            </Row>
            <Row center>
              <h4 css={css`margin-right: 3vw;`}>Twitter:</h4>
              <h6>@goinsidebarre</h6>
            </Row>
          </Column>
        </Row>
      </FullBanner>
    </Layout>
  )
}

export default IndexPage
